import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useMyServicesController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  checkServiceDetailsRequestQueue,
  getPropertyDetailsQuestionsFor,
} from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { InstantQuoteType, ServiceStandard } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { MyServices } from '@lawnstarter/ls-react-common/templates';

import { ResponsiveSectionWrapper } from '@src/components';
import { HeaderTabs } from '@src/enums';
import { useRouteNavigation } from '@src/hooks';
import { AuthorizedTemplate } from '@src/pages';
import { errorService } from '@src/services';

import { StyledServiceContainer, StyledServiceWrapper } from './styles';

import type { Feed } from '@lawnstarter/ls-react-common/organisms';
import type { User } from '@lawnstarter/ls-react-common/types';

export function AuthorizedServicesTemplate({ user }: { user?: User }) {
  const { pathname } = useLocation();
  const { navigate } = useRouteNavigation();
  const { scheduleId, sourceId } = useParams();
  const propertyDetails = useModal(ModalTemplate.OPTIONAL_ACTION);

  const { isLoading, refreshData, subscriptions } = useMyServicesController({ errorService });

  useEffect(() => {
    refreshData();
  }, [pathname, refreshData]);

  checkServiceDetailsRequestQueue({
    navigate: (service) => {
      const details = getPropertyDetailsQuestionsFor(service);
      if (details) {
        propertyDetails.show({
          ...details.startScreen,
          onAction: _.noop, // TODO: https://lawnstarter.atlassian.net/browse/PE-48371
        });
      }
    },
  });

  const currentSelectedItem = useMemo(() => {
    if (subscriptions.length === 0) {
      return;
    }

    if (!scheduleId && !sourceId) {
      return subscriptions[0].key;
    }

    return subscriptions.find(({ params }) => {
      const hasSourceId = sourceId && params['sourceId']?.toString() === sourceId.toString();
      const hasScheduleId =
        scheduleId && params['schedule_id']?.toString() === scheduleId.toString();
      return hasSourceId || (!sourceId && hasScheduleId);
    })?.key;
  }, [subscriptions, scheduleId, sourceId]);

  const handleSubscriptionNavigation = ({ params = {}, type }: Feed) => {
    const { property_id, schedule_id, sourceId } = params as {
      property_id: number;
      schedule_id: number;
      sourceId: number;
    };

    switch (type) {
      case InstantQuoteType.FlowerBedWeeding:
      case InstantQuoteType.BushTrimming:
      case InstantQuoteType.LeafRemoval:
        return navigate(WebRoutes.pendingInstantQuote, {
          params: {
            propertyId: property_id,
            sourceId,
          },
        });

      case ServiceStandard.Required:
        return navigate(WebRoutes.requiredQuoteDetails, {
          params: {
            propertyId: property_id,
            ...params,
          },
        });

      case ServiceStandard.Recommended:
        return navigate(WebRoutes.recommendedQuoteDetails, {
          params: {
            propertyId: property_id,
            ...params,
          },
        });

      case InstantQuoteType.LongGrass:
        return navigate(WebRoutes.longGrassQuoteReport, {
          params: {
            propertyId: params.property_id,
            scheduleId: params.schedule_id,
            scheduleEventId: params.schedule_event_id,
            ...params,
          },
        });

      default:
        return navigate(WebRoutes.serviceDetail, {
          params: {
            propertyId: property_id,
            scheduleId: schedule_id,
          },
        });
    }
  };

  const renderServicesOptions = () => {
    return (
      <MyServices
        selectedFeedKey={currentSelectedItem}
        isLoading={isLoading}
        onRefresh={refreshData}
        subscriptions={subscriptions}
        onFeedSelect={handleSubscriptionNavigation}
        notAvailable={{
          title: t('noServices.noServicesYet'),
          description: t('noServices.noOpenServices'),
          action: t('bookNow'),
          fallbackAction: () => navigate(WebRoutes.browseRedirect),
        }}
      />
    );
  };

  return (
    <AuthorizedTemplate currentTab={HeaderTabs.services} user={user}>
      <StyledServiceWrapper>
        {!!subscriptions.length && (
          <div className="max-md:hidden">
            <Text variant="headlineSmall" style={{ ...setFontWeight('600') }}>
              {t('scheduledServices')}
            </Text>
          </div>
        )}

        <StyledServiceContainer>
          <ResponsiveSectionWrapper
            isLoading={isLoading}
            hasRightContent={!!subscriptions.length}
            hideLeftContentWhen={pathname !== WebRoutes.services}
            leftClassName="max-md:w-full md:pb-4 bg-transparent border-none"
            leftChildren={renderServicesOptions()}
            rightChildren={<Outlet />}
          />
        </StyledServiceContainer>
      </StyledServiceWrapper>
    </AuthorizedTemplate>
  );
}
