import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useServicesController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceSourceType as SourceType } from '@lawnstarter/ls-react-common/enums';
import { ActionUnavailable } from '@lawnstarter/ls-react-common/molecules';
import { ServicesList } from '@lawnstarter/ls-react-common/templates';

import { ResponsiveSectionWrapper } from '@src/components';
import { DEVICE_WIDTH_BREAKPOINTS as breakpoints } from '@src/constants';
import { HeaderTabs } from '@src/enums';
import { useRouteNavigation, useWindowSize } from '@src/hooks';
import { AuthorizedTemplate } from '@src/pages';

import { StyledServiceContainer, StyledServiceWrapper } from './styles';

import type { User } from '@lawnstarter/ls-react-common/types';

export function AuthorizedServicesTemplateV2({ user }: { user?: User }) {
  const { scheduleId, sourceId } = useParams();
  const { pathname } = useLocation();
  const { navigate } = useRouteNavigation();
  const { width: deviceWidth } = useWindowSize();

  const { isLoading, items, onRefresh } = useServicesController();

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  const selectedKey = useMemo(() => {
    if (!items.length || deviceWidth < breakpoints.tablet.max) {
      return undefined;
    }

    if (!scheduleId && !sourceId) {
      return items[0].key;
    }

    return items.find((item) => {
      const hasSource = Boolean(sourceId) && String(item?.sourceId) === String(sourceId);
      const hasSchedule = Boolean(scheduleId) && String(item?.scheduleId) === String(scheduleId);
      return hasSource || (!sourceId && hasSchedule);
    })?.key;
  }, [deviceWidth, items, scheduleId, sourceId]);

  function onSelect(item: (typeof items)[0]) {
    if (item.sourceType === SourceType.InstantQuote) {
      return navigate(WebRoutes.pendingInstantQuote, {
        params: { propertyId: item.propertyId!, sourceId: item.sourceId! },
      });
    }

    if (item.sourceType === SourceType.ServiceQuote) {
      return navigate(WebRoutes.recommendedQuoteDetails, {
        params: { propertyId: item.propertyId!, sourceId: item.sourceId! },
      });
    }

    return navigate(WebRoutes.serviceDetail, {
      params: { propertyId: item.propertyId!, scheduleId: item.scheduleId! },
    });
  }

  return (
    <AuthorizedTemplate currentTab={HeaderTabs.services} user={user}>
      <StyledServiceWrapper className="bg-whiteSecondary">
        {!!items.length && (
          <div className="max-md:hidden">
            <Text variant="headlineSmall" style={{ ...setFontWeight('600') }}>
              {t('scheduledServices')}
            </Text>
          </div>
        )}

        <StyledServiceContainer>
          <ResponsiveSectionWrapper
            isLoading={isLoading}
            hasRightContent={!!items.length}
            hideLeftContentWhen={pathname !== WebRoutes.services}
            className="gap-6 md:-mt-2 md:-ml-2"
            leftClassName="max-md:w-full md:pb-4 bg-transparent border-none p-4 pt-3 md:p-2 md:mr-0"
            leftChildren={
              <ServicesList
                selectedKey={selectedKey}
                isLoading={isLoading}
                onRefresh={onRefresh}
                items={items.map((item) => ({
                  ...item,
                  button: item.button
                    ? { ...item.button, onPress: () => onSelect(item) }
                    : undefined,
                }))}
                onSelect={onSelect}
                emptyComponent={
                  <ActionUnavailable
                    action={t('bookNow')}
                    description={t('noServices.noOpenServices')}
                    fallbackAction={() => navigate(WebRoutes.browseRedirect)}
                    title={t('noServices.noServicesYet')}
                  />
                }
              />
            }
            rightChildren={<Outlet />}
            rightClassName="md:mt-2"
          />
        </StyledServiceContainer>
      </StyledServiceWrapper>
    </AuthorizedTemplate>
  );
}
