import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { Toast } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation, useSelector } from '@src/hooks';

import { StyledPaymentNotice } from './style';

export function PaymentNotice() {
  const { navigate } = useRouteNavigation();

  const property = useSelector(properties_currentPropertySelector);
  const hasPaymentFailure = useSelector((state) => state.auth.user?.customer?.has_payment_failure);

  const onPress = () => navigate(WebRoutes.updatePayment);

  return (
    hasPaymentFailure && (
      <StyledPaymentNotice>
        <Toast
          description={
            property?.is_active ? t('notices.paymentWillCancel') : t('notices.paymentCanceled')
          }
          variant="alert"
          action={{
            label: t('updateNow'),
            onPress,
          }}
          msToHide={0}
        />
      </StyledPaymentNotice>
    )
  );
}
