import tw from 'tailwind-styled-components';

interface StyledContainedSectionProps {
  $hideFooter?: boolean;
}

export const StyledContainedSection = tw.section<StyledContainedSectionProps>`
  flex flex-col mx-auto responsive-container
  max-md:mb-1
  md:my-6 grow md:grow-0
  ${({ $hideFooter }) => !$hideFooter && 'max-md:pb-[var(--footer-size)]'}
`;
